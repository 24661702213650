//
// Global Scripts (jquery and plugins.js must be loaded before this file)
//
// Utility Functions
// =======================================================
// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function() {};
  var methods = [
    'assert',
    'clear',
    'count',
    'debug',
    'dir',
    'dirxml',
    'error',
    'exception',
    'group',
    'groupCollapsed',
    'groupEnd',
    'info',
    'log',
    'markTimeline',
    'profile',
    'profileEnd',
    'table',
    'time',
    'timeEnd',
    'timeline',
    'timelineEnd',
    'timeStamp',
    'trace',
    'warn'
  ];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();

// Custom commands and emoji
console.yep = console.log.bind(console, '👍');
console.nope = console.log.bind(console, '👎');

(function($) {
  window.site = window.site || {};

  site.cacheSelectors = function() {
    site.cache = {
      // General
      $html: $('html'),
      $body: $('body'),
      // Navigation
      $navigation: $('#SiteNavMenu'),
      $mobileSubNavToggle: $('.mobile-nav__toggle-button')
    };
  };

  site.init = function() {
    FastClick.attach(document.body);
    site.cacheSelectors();
    site.drawersInit();
    site.mobileNavToggle();
  };

  // /*============================================================================
  // Drawer modules
  // ==============================================================================*/
  site.Drawers = (function() {
    var Drawer = function(id, position, options) {
      var defaults = {
        close: '.js-drawer-close',
        open: '.js-drawer-open-' + position,
        openClass: 'js-drawer-open',
        dirOpenClass: 'js-drawer-open-' + position
      };

      this.$nodes = {
        parent: $('body, html'),
        page: $('#PageContainer'),
        moved: $('.is-moved-by-drawer')
      };

      this.config = $.extend(defaults, options);
      this.position = position;

      this.$drawer = $('#' + id);

      if (!this.$drawer.length) {
        return false;
      }

      this.drawerIsOpen = false;
      this.init();
    };

    Drawer.prototype.init = function() {
      $(this.config.open).on('click', $.proxy(this.open, this));
      this.$drawer.find(this.config.close).on('click', $.proxy(this.close, this));
    };

    Drawer.prototype.open = function(evt) {
      // Keep track if drawer was opened from a click, or called by another function
      var externalCall = false;

      // Prevent following href if link is clicked
      if (evt) {
        evt.preventDefault();
      } else {
        externalCall = true;
      }

      // Without this, the drawer opens, the click event bubbles up to $nodes.page
      // which closes the drawer.
      if (evt && evt.stopPropagation) {
        evt.stopPropagation();
        // save the source of the click, we'll focus to this on close
        this.$activeSource = $(evt.currentTarget);
      }

      if (this.drawerIsOpen && !externalCall) {
        return this.close();
      }

      // Add is-transitioning class to moved elements on open so drawer can have
      // transition for close animation
      this.$nodes.moved.addClass('is-transitioning');
      this.$drawer.prepareTransition();

      this.$nodes.parent.addClass(this.config.openClass + ' ' + this.config.dirOpenClass);
      this.drawerIsOpen = true;

      // Set focus on drawer
      this.trapFocus(this.$drawer, 'drawer_focus');

      // Run function when draw opens if set
      if (this.config.onDrawerOpen && typeof this.config.onDrawerOpen == 'function') {
        if (!externalCall) {
          this.config.onDrawerOpen();
        }
      }

      if (this.$activeSource && this.$activeSource.attr('aria-expanded')) {
        this.$activeSource.attr('aria-expanded', 'true');
      }

      // Lock scrolling on mobile
      this.$nodes.page.on('touchmove.drawer', function() {
        return false;
      });

      this.$nodes.page.on(
        'click.drawer',
        $.proxy(function() {
          this.close();
          return false;
        }, this)
      );
    };

    Drawer.prototype.close = function() {
      if (!this.drawerIsOpen) {
        // don't close a closed drawer
        return;
      }

      // deselect any focused form elements
      $(document.activeElement).trigger('blur');

      // Ensure closing transition is applied to moved elements, like the nav
      this.$nodes.moved.prepareTransition({
        disableExisting: true
      });
      this.$drawer.prepareTransition({
        disableExisting: true
      });

      this.$nodes.parent.removeClass(this.config.dirOpenClass + ' ' + this.config.openClass);

      this.drawerIsOpen = false;

      // Remove focus on drawer
      this.removeTrapFocus(this.$drawer, 'drawer_focus');

      this.$nodes.page.off('.drawer');
    };

    Drawer.prototype.trapFocus = function($container, eventNamespace) {
      var eventName = eventNamespace ? 'focusin.' + eventNamespace : 'focusin';

      $container.attr('tabindex', '-1');

      $container.focus();

      $(document).on(eventName, function(evt) {
        if ($container[0] !== evt.target && !$container.has(evt.target).length) {
          $container.focus();
        }
      });
    };

    Drawer.prototype.removeTrapFocus = function($container, eventNamespace) {
      var eventName = eventNamespace ? 'focusin.' + eventNamespace : 'focusin';

      $container.removeAttr('tabindex');
      $(document).off(eventName);
    };

    return Drawer;
  })();

  site.drawersInit = function() {
    site.LeftDrawer = new site.Drawers('NavDrawer', 'left');
    site.RightDrawer = new site.Drawers('CartDrawer', 'right', {
    });
  };

  site.mobileNavToggle = function() {
    site.cache.$mobileSubNavToggle.on('click', function() {
      $(this)
        .closest('.mobile-nav__item')
        .toggleClass('mobile-nav__item--expanded');
    });
  };

  // Initialize site's JS on docready
  $(site.init);

  // ==========================================================================
  // Load Posts From The Blog
  // ==========================================================================

  // Handlebars Helper for debuggin jsom response
  // example {{{json someOject}}}
  // Handlebars.registerHelper('json', function(context) {
  //   return JSON.stringify(context);
  // });

  //Check if the blog post widget exists on the page
  if ($('#jsFeedOutput').length) {
    // render the posts

    renderPosts(
      'https://summitsupplychainsolutions.com/blog/wp-json/wp/v2/posts/?per_page=3&_embed',
      // 'https://cors-anywhere.herokuapp.com/https://summitsupplychainsolutions.com/blog/wp-json/wp/v2/posts/?per_page=3&_embed',
      '#jsFeedEntryTemplate',
      '#jsFeedOutput'
    );
  }
  /* =====================================================
	Render the Posts
  ====================================================== */
  function renderPosts(url, templateId, templateOutputId) {
    $(templateOutputId + ' .loader').show();
    $.getJSON(url).done(function(data) {
      var feedEntry = $(templateId).html();
      var template = Handlebars.compile(feedEntry);
      var html = template(data);
      $(templateOutputId).append(html);
      $(templateOutputId + ' .loader').hide();
    });
  }

  console.log('====================================');
  console.log('main.js loaded');
  console.log('====================================');
})(jQuery);
